@import '../../../sass/variables';

.solutions {
    .text-right {
        text-align: right;
        margin-bottom: 0;
        padding-right: 30px;
    }

    .btn {
        margin: 0 !important;
        padding: 7px 10px;
    }

    .col-md-4 {
        .h3 {
            font-weight: bold;
        }
    }
}

/*light variant */
.solutions-light {
    background-color: $breadCrumbBackgroundColor;
    border-bottom: 2px solid $solBorderGrey;
    padding: 10px 0;

    .link-padding {
        color: $solBlue;
        padding: 0 10px 19px;
        font-size: 16px;

        &:hover {
            border-bottom: 3px solid $solBlue;
            color: $solBlue;
            padding-bottom: 19px;
            text-decoration: none;
        }
    }

    .col-md-4 {
        .h3 {
            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}

.active-link {
    border-bottom: 3px solid $solBlue;
    color: $solBlue;
    text-decoration: none;
}

/* dark variant */
.solutions-dark {
    background-color: $solutionsDark;
    border-bottom: 3px solid $solBorderGrey;
    color: $white;
    padding: 10px 0;

    .link-padding {
        color: inherit;
        padding: 0 10px 19px;
        font-size: 16px;

        &:hover {
            border-bottom: 3px solid $solutionsDarkButtonBlue;
            color: $solutionsDarkButtonBlue;
            padding-bottom: 19px;
            text-decoration: none;
        }
    }

    .col-md-4 {
        .h3 {
            &:hover,
            &:focus {
                color: inherit;
                text-decoration: none;
            }
        }
    }

    .active-link {
        border-bottom: 3px solid $solutionsDarkButtonBlue;
        color: $solutionsDarkButtonBlue;
        text-decoration: none;
    }
}

.solutions-mobile {
    background-color: $breadCrumbBackgroundColor;
    border-bottom: 2px solid $solBorderGrey;
    padding: 10px 0;

    .collapse {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .active-link {
        border: none;
    }

    /* mobile dark */
    .solutions-mobile-dark {
        background-color: $solutionsDark;
        border-bottom: 2px solid $solBorderGrey;
        color: $white;
        padding: 10px 0;

        .list-items {
            ul {
                list-style-type: none;
                margin-top: 12px;
                border-top: 1px solid $breadCrumbBackgroundColor !important;
                padding-left: 0;

                li {
                    &:first-of-type {
                        padding-top: 30px;
                    }
                    a {
                        color: $white !important;
                        padding: 0;

                        &:hover {
                            color: $solutionsDarkButtonBlue !important;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .h3 {
            &:hover,
            &:focus {
                color: inherit;
                text-decoration: underline;
            }
        }

        .fa-bars {
            color: $solutionsDarkButtonBlue !important;
        }

        .ngp-icon {
            color: $white !important;
            float: right;
            right: -10px;
        }
    }

    /* mobile light */
    .solutions-mobile {
        .smort-center {
            display: flex;
            align-items: center;
        }
        .text-right {
            text-align: right;
        }

        .btn {
            margin: 0 !important;
            padding: 8px 10px;
        }

        .h3 {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .col-xs-7 {
            padding-left: 10px;

            a {
                &:hover {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }

        .col-xs-4 {
            padding-left: 7px;
        }

        .list-items {
            ul {
                list-style-type: none;
                margin-top: 12px;
                border-top: 1px solid black;
                padding-left: 0;

                li {
                    &:first-of-type {
                        padding-top: 30px;
                    }
                    a {
                        color: $black;
                        padding: 0;

                        &:hover {
                            color: $solBlue;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .fa-bars {
            color: $solBlue;
        }

        .ngp-icon {
            color: $black;
            float: right;
            right: -10px;
        }
    }
}

@media only screen and (max-width: 425px) {
    .mobile-title {
        width: 230px;
        text-align: center;
    }

    .btn-solutions-light,
    .btn-solutions-dark {
        white-space: normal;
    }

    .col-xs-4 {
        right: -3px;
    }
}

@media only screen and (max-width: 375px) {
    .truncate {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .h3 {
        padding-left: 5px;
    }

    .col-xs-4 {
        right: 17px;
    }
}

@media only screen and (max-width: 320px) {
    .truncate {
        width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .h3 {
        padding-left: 5px;
    }

    .col-xs-4 {
        right: 35px;
    }
}
